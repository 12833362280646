<template>
    <div class="repair">
        <Headers :headerData="headerData.head" />

        <Head :TitleData="TitleData" />
        <TextListBgc :textData="textData" />
        <div style="position: absolute;left: 400px;bottom: -212px;font-size: 12px;color: #1386B0">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div>
    </div>
</template>
<script>
import Head from "./component/Head.vue"
import TextListBgc from "./component/TextListBgc.vue"
import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
export default {
    data() {
        return {
            TitleData: {
                icnosW: '118px',
                icnosH: '119px',
                iconUrl: 'https://image.giantgocloud.com/www//ImageMapping/image/20230811/02932BA55D4B461ABC4F4FAFFEDFB5CE.png',
                name: '炎症',
                textList: [
                    '保持低炎症水平对你的身体健康很重要。 ',
                    '当炎症水平较低时，你会感觉更好，会保持更健康，病情也会有所改善 你的表现，并提高你的寿命。'
                ]
            },
            textData: [
                {
                    name: '—（高敏c反应蛋白） hsCRP',
                    text: 'hsCRP是一种蛋白质，是全身炎症的一般标志物。当hsCRP测试显示CRP的最佳水平时，体内的炎症量非常低。 最佳的hsCRP水平似乎是一个健康的心脏、循环系统、血压和血糖的有效预测因子。hsCRP试验对体内CRP的含 量非常敏感，因此比普通的CRP试验是一个更好的炎症指标。'
                },
                {
                    name: '—（白细胞）（WBC）计数',
                    text: 'WBCs是免疫系统中的感染战士。你的白细胞计数是全身炎症的一个指标。你的白细胞计数越高，炎症就越 多。了解你的白细胞状态将帮助你保持你的整体健康。'
                },
                {
                    name: '—（全血细胞计数）（CBC）WBC类型',
                    text: 'CBC是一种常见的有序测试，它可以告诉你很多关于你的整体健康状况和性能的信息。CBC测量的白细胞类型为：中性粒细胞、淋巴细胞、单核细胞、嗜碱性粒细胞和嗜酸性粒细胞。这些白细胞类型在应对感染、 高训练负荷、情绪或身体压力以及过敏等反应方面发挥着重要作用。因为它们会对这些身体压力源做出反 应，所以它们也会在揭示你的整体炎症水平方面发挥作用。'
                },
                {
                    name: '—中性粒细胞',
                    text: '中性粒细胞（neutrophils）在人体健康中具有重要作用， 中性粒细胞能够吞噬和消化病原体，并释放出细胞毒素、酶和其他抗菌物质， 以杀灭细菌和抑制感染的扩散，帮助清除病原体并促进伤口的修复， 同时中性粒细胞能够与其他免疫细胞相互作用，调节免疫应答的平衡和稳定。'
                },
            ],
        }
    },
    components: {
        Head,
        TextListBgc,
        Headers,
        Footer
    },
    props: {
        Sex: String,
        headerData: Object,
    },

    computed: {
        "totalNumber": function () {
            let result = { count: 0, currentPage: 0 }
            result.count = this.headerData.pageCount
            //计算当前页码
            let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "炎症1")
            result.currentPage = currentIndex + 1
            return result
        }
    }
}
</script>

<style lang='scss' scoped>
.repair {
    position: relative;
}

.repair1 {
    font-size: 48px;
    color: #1386B0;
    padding-top: 51px;
    padding-left: 45px;
}

.repair2 {
    font-size: 12px;
    color: #4E4E4E;
    padding: 0 45px;
    padding-top: 43px;
}

.repair3 {
    padding-top: 29px;
    padding-left: 45px;

    .title {
        font-size: 14px;
        color: #1386B0;
    }

    .text {
        color: #4E4E4E;
        font-size: 10px;
        padding-top: 4px;
    }
}

.repair4 {
    width: 595px;
    height: 351px;
    margin-top: 59px;

    img {
        width: 100%;
        height: 100%;
    }
}

.page {
    position: absolute;
    left: 543px;
    bottom: -220px;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background-image: url('https://image.giantgocloud.com/www//ImageMapping/image/20230811/47D3D6C542304B24A4EFC6A4FB3D8064.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
</style>