var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recipes"},[_c('div',{staticClass:"funMedicine coverTitle",staticStyle:{"margin-top":"0"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"messageinfo"},[_c('div',[_vm._v("姓名 / Compellation："+_vm._s(_vm.listData.reportUserInfo.Name))]),_c('div',[_vm._v("性别 / Gender："+_vm._s(_vm.listData.reportUserInfo.Sex))]),_c('div',[_vm._v("年龄 / Age："+_vm._s(_vm.listData.reportUserInfo.Age))]),_c('div',[_vm._v("出生日期 / Date of birth："+_vm._s(_vm.listData.reportUserInfo.Birth))]),_c('div',[_vm._v("检测日期 / Test date："+_vm._s(_vm.listData.reportUserInfo.CheckDate))])]),_vm._m(3)]),_c('div',{staticClass:"funMedicine funMedicinesecod zZjpagesNum"},[_c('div',{staticClass:"funMedicinesecod_line"}),_vm._m(4),_vm._m(5),_c('div',{staticStyle:{"position":"absolute","left":"360px","bottom":"30px","font-size":"12px","color":"#1386B0"}},[_vm._v(" 终极健康分析（共"+_vm._s(_vm.alNum)+"页）P1 ")])]),_c('div',{staticClass:"funMedicine zZjpagesNum"},[_c('directory',{attrs:{"alNum":_vm.alNum,"numPageA":_vm.numPageA,"Sex":_vm.Sex,"directoryList":_vm.listData.finalAnalysisSickItem}})],1),_c('div',{staticClass:"funMedicine zZjpagesNum"},[_c('Level',{attrs:{"alNum":_vm.alNum,"numPageA":_vm.numPageA,"Sex":_vm.Sex,"LevelDetails":_vm.listData.LevelDetails}})],1),_vm._l((_vm.listData.finalAnalysisDataDetailTitleDtos),function(item,index){return _c('div',{key:index},[_c('Headers',{attrs:{"alNum":_vm.alNum,"finalAnalysisDataDetailTitleDtos":item}}),_c('Indicator',{attrs:{"alNum":_vm.alNum,"finalAnalysisSickItem":_vm.listData.finalAnalysisSickItem[index],"listData":_vm.listData,"typeIndex":index}})],1)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coverTitleTop"},[_c('img',{attrs:{"src":"https://image.giantgocloud.com/www//ImageMapping/image/20231101/0C159F4A4DD44D35A1F581EE94A4B1C9.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coverTitle_title"},[_c('img',{attrs:{"src":"https://image.giantgocloud.com/www//ImageMapping/image/20231130/2789D6DAE8FF4038BE1F61507A87AC56.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coverTitle_content"},[_c('img',{attrs:{"src":"https://image.giantgocloud.com/www//ImageMapping/image/20231101/33ACFC1BBF324E9AA89DB82D0FD8EFA1.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"first_bottom"},[_c('div',{staticClass:"first_bottom1"},[_c('img',{attrs:{"src":"https://image.giantgocloud.com/www//ImageMapping/image/20231101/8060313FF8FB416E837B9C1BCA38DC9F.png","alt":""}})]),_c('div',{staticClass:"first_bottom2"},[_vm._v("© 2023 SCALLOP CLOUD | ealth Guardian")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"funMedicinesecodImg"},[_c('img',{attrs:{"src":"https://image.giantgocloud.com/www//ImageMapping/image/20231101/5229756316E64CABAF93EC50781B648C.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"funMedicinesecodContend"},[_c('div',{staticClass:"funMedicinesecodContend1"},[_vm._v("全面洞察您的生理数据")]),_c('div',{staticClass:"funMedicinesecodContend2"},[_vm._v(" 终极健康计划测试多达58种血液生物标志物，包括：心脏健康、荷尔蒙健康、新陈代谢、免疫与炎症、睡眠健康、肠道健康、认知力、修复力、耐力，以及营养物质，对您的健康状况进行全面的分析。")]),_c('div',{staticClass:"funMedicinesecodContend3"},[_vm._v(" 了解您的真实生理年龄是有益健康变化的重要动力和催化剂，由于血液生物标志物受到生活习惯的影响，血液检测有助于确定您需要采取的行动来改善您的健康。即使您现在感觉健康，即使没有身体症状，健康衰老的关键标志也会开始走下坡路。 ")])])
}]

export { render, staticRenderFns }