<template>
    <div class="repair">
        <Headers :headerData="headerData.head" />

        <Head :TitleData="TitleData" />
        <TextListBgc :textData="textData" />
        <div style="position: absolute;left: 400px;bottom: -86px;font-size: 12px;color: #1386B0">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div> 
    </div>
</template>
<script>
import Head from "./component/Head.vue"
import TextListBgc from "./component/TextListBgc.vue"
import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
export default {
    data() {
        return {
            TitleData: {
                icnosW: '131px',
                icnosH: '158px',
                iconUrl: 'https://image.giantgocloud.com/www//ImageMapping/image/20230811/1A378F483341484399C6C15434F9CA40.png',
                name: '修复',
                textList: ['修复力是指人体恢复和修复受伤组织的能力。无论是外伤、 手术创伤还是内部炎症，修复力的 强大与否都会直接影响受伤组织的康复速度和质量。']
            },
            textData: [
                {
                    name: '—肌酸激酶',
                    text: 'CK是健康肌肉细胞中的一种酶，在运动的前几秒钟产生能量中起着重要作用。剧烈的运动会损害肌肉细胞，导 致CK漏入血液中。CK水平的升高表明了肌肉的损伤。这将导致疲劳的快速发作，更大的受伤风险，和更慢的恢复时间。'
                },
                {
                    name: '—（天冬氨酸转氨酶）AST',
                    text: 'AST是一种酶，主要存在于肝脏中，也存在于心脏、肌肉组织、肾脏、大脑和红细胞中。AST有助于代谢氨基酸 。虽然血液中通常存在少量的AST，但运动和肝损伤可导致AST升高。有了最佳的AST水平，你将有更多的能量， 更有效地代谢食物，恢复得更快。'
                },
                {
                    name: '—（谷氨酰转肽酶）GGT',
                    text: 'GGT是一种集中在肝脏中的酶，也存在于胆管、胰腺、脾脏和肾脏中。GGT有助于跨细胞膜转移氨基酸，并在帮助 肝脏代谢毒素中发挥重要作用。GGT的升高与肝损伤密切相关， 比ALT和AST更密切相关，使其成为肝脏健康的重 要生物标志物。'
                },
                {
                    name: '—谷丙转氨酶',
                    text: '谷丙转氨酶(ALT)是一种主要存在于肝脏的酶，有助于发生化学反应。它在将储存的葡萄糖转化为可用的能量方 面发挥着作用。当出现肝损伤或疾病时，谷丙转氨酶就会进入血流。血液中通常含有少量的谷丙转氨酶；血液 中 谷丙转氨酶含量较高，通常表明肝脏或肌肉受损。你的肝脏将你吃的和喝的加工成你身体使用的能量和营养 过滤掉你血液中的有害物质。'
                },
                {
                    name: '—白蛋白',
                    text: '白蛋白是一种由肝脏制造的蛋白质，通过血液运输许多分子，包括睾酮和SHBG。最佳白蛋白水平表明你可能在 饮食中摄入正常数量的蛋白质，与其他生物标志物一起，可以提供你的肾脏和/或肝脏健康状况的信息。'
                },
                {
                    name: '—尿酸',
                    text: '尿酸（Uric Acid）在人体中具有一定的重要性，但过高或过低的尿酸水平都可能对健康产生影响，尿酸是体内 的一种抗氧化物质，具有清除自由基的能力，高尿酸水平与痛风相关，与代谢综合征、高血压、心血管疾病的 发生风险增加有关。'
                }
            ],
            //   coverManImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/98DA1027B2244A42AD2A1821B6E9D48C.png',
            //   coverGirlImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/F47180B6A32243E7A4583CB59C2BF8AB.png',
        }
    },
    components: {
        Head,
        TextListBgc,
        Headers,
        Footer
    },
    props: {
        Sex: String,
        headerData: Object,
    },
    computed: {
        "totalNumber": function () {
            let result = { count: 0, currentPage: 0 }
            result.count = this.headerData.pageCount
            //计算当前页码
            let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "修复1")
            result.currentPage = currentIndex + 1
            return result
        }
    }
}
</script>

<style lang='scss' scoped>
.repair {
    position: relative; 
}

.repair1 {
    font-size: 48px;
    color: #1386B0;
    padding-top: 51px;
    padding-left: 45px;
}

.repair2 {
    font-size: 12px;
    color: #4E4E4E;
    padding: 0 45px;
    padding-top: 43px;
}

.repair3 {
    padding-top: 29px;
    padding-left: 45px;

    .title {
        font-size: 14px;
        color: #1386B0;
    }

    .text {
        color: #4E4E4E;
        font-size: 10px;
        padding-top: 4px;
    }
}

.repair4 {
    width: 595px;
    height: 351px;
    margin-top: 59px;

    img {
        width: 100%;
        height: 100%;
    }
}

.page {
    position: absolute;
    left: 543px;
    bottom: -34px;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background-image: url('https://image.giantgocloud.com/www//ImageMapping/image/20230811/47D3D6C542304B24A4EFC6A4FB3D8064.png');
    background-repeat: no-repeat;
    background-size: 100%;
}</style>