<template>
    <div class="repair">
        <Headers :headerData="headerData.head" />

        <Head :TitleData="TitleData" />
        <TextListBgc :textData="textData" />
        <div class="repair1">
            <img src="https://image.giantgocloud.com/www//ImageMapping/image/20230811/78BB420414C743C1B82A870A76DFD425.png"
                alt="">
        </div>
        <div style="position: absolute;left: 400px;bottom: 5px;font-size: 12px;color: #FFFFFF">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div>
    </div>
</template>
<script>
import Head from "./component/Head.vue"
import TextListBgc from "./component/TextListBgc.vue"
import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
export default {
    data() {
        return {
            TitleData: {
                icnosW: '118px',
                icnosH: '119px',
                iconUrl: 'https://image.giantgocloud.com/www//ImageMapping/image/20230811/02932BA55D4B461ABC4F4FAFFEDFB5CE.png',
                name: '炎症',
                textList: [
                    '保持低炎症水平对你的身体健康很重要。 ',
                    '当炎症水平较低时，你会感觉更好，会保持更健康，病情也会有所改善 你的表现，并提高你的寿命。'
                ]
            },
            textData: [
                {
                    name: '—嗜碱性粒细胞',
                    text: '嗜碱性粒细胞（basophils）在人体健康中也具有重要作用，它们对过敏反应和变态反应起重要作用，参与调控免疫平衡，它们在过敏性疾病（如过敏性鼻炎、哮喘等）的发生和发展过程中起关键作用，嗜碱性粒细胞能够释放抗寄生虫物质，协助机体抵御寄生虫感染。'
                },
                {
                    name: '—嗜酸性粒细胞',
                    text: '嗜酸性粒细胞（eosinophils）在人体健康中具有重要作用，它们参与调节和调控免疫反应，在过敏反应中，嗜酸性粒细胞的过度激活可能导致过敏症状的发生，有助于修复受损的组织，嗜酸性粒细胞的异常功能可能与自身免疫疾病和过敏性疾病等免疫性疾病有关。'
                },
                {
                    name: '—淋巴细胞',
                    text: '淋巴细胞在人体健康中扮演着重要的角色，它们参与抵抗病原体、识别和消灭异常细胞、产生抗体等免疫反应当接触同一病原体时，淋巴细胞能够更快地反应，并生成更强大的免疫应答，提供长期保护， 淋巴细胞对肿瘤免疫具有重要作用。'
                },
                {
                    name: '—单核细胞',
                    text: '单核细胞在人体健康中扮演着重要的角色，单核细胞是免疫系统中的一类重要细胞，它们参与调节免疫反应和抵抗病原体，它们清除死亡细胞和组织垃圾，促进伤口愈合和组织恢复，分泌抗炎因子，平衡和调节炎症反应，以防止过度炎症反应和组织损伤，对脂肪代谢和能量平衡有一定的调节作用。'
                },
            ],
        }
    },
    components: {
        Head,
        TextListBgc,
        Headers,
        Footer
    },
    props: {
        Sex: String,
        headerData: Object,

    },
    computed: {
        "totalNumber": function () {
            let result = { count: 0, currentPage: 0 }
            result.count = this.headerData.pageCount
            //计算当前页码
            let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "炎症2")
            result.currentPage = currentIndex + 1
            return result
        }
    }
}
</script>

<style lang='scss' scoped>
.repair {
    position: relative;
}

.repair1 {
    width: 595px;
    height: 216px;

    img {
        width: 100%;
        height: 100%;
    }
}

.page {
    position: absolute;
    left: 543px;
    bottom: 0;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background-image: url('https://image.giantgocloud.com/www//ImageMapping/image/20230811/47D3D6C542304B24A4EFC6A4FB3D8064.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
</style>