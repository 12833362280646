<template>
    <div class="home1">
        <div style="padding-left: 67px;padding-top: 55px;">
            <h1 style="font-size: 48px;color: #1386B0;">全面洞察</h1>
        <h1 style="font-size: 48px;color: #1386B0;">您的生理数据</h1>
        </div>
        <div style="font-size: 14px;color: #4E4E4E;padding-left: 67px;padding-right: 40px;padding-top: 15px;">
            <p>
                终极健康计划测试多达58种血液生物标志物，包括：心脏健康、荷尔蒙健康、新陈代谢、免疫与炎症、睡眠健康、肠道健康、认知力、修复力、耐力，以及营养物质，对您的健康状况进行全面的分析。
            </p>
            <p style="padding-top: 10px;">
                了解您的真实生理年龄是有益健康变化的重要动力和催化剂，由于血液生物标志物受到生活习惯的影响，血液检测有助于确定您需要采取的行动来改善您的健康。即使您现在感觉健康，即使没有身体症状，健康衰老的关键标志也会开始走下坡路。
            </p>
        </div>
        <div class="home1-img">
            <template v-if="Sex == '男'">
                <img src="https://image.giantgocloud.com/www//ImageMapping/image/20230811/AB6F1964785B4F8C9BD56EAD881ACD38.png" alt="">
            </template>
          <template v-else>
            <img src="https://image.giantgocloud.com/www//ImageMapping/image/20230811/255B66AA6771418388A2431EA389D8E4.png" alt="">
          </template>
        </div>
        <div style="width: 595px;height: 180px;background-color: #D0E7EF;">

        </div>
    </div>
</template>
<script>
export default {
    props: {
        Sex: String
    }
}
</script>
<style lang='scss' scoped>
    .home1-img{
        width: 595px;
        height: 252px;
        padding-top: 30px;
        img{
            width: 100%;
            height: 100%;
        }
    }

</style>