<template>
    <div class="repair">
        <Headers :headerData="headerData.head" />
        <Head :TitleData="TitleData" />
        <TextListBgc :textData="textData"/>
             <template v-if="Sex == '男'">
            <div class="repair4">
                <img src="https://image.giantgocloud.com/www//ImageMapping/image/20230811/0C4B7BB3D5294FDCA7FFAFA2EB5E0B1B.png"
                    alt="">
            </div>
            <div style="position: absolute;left: 400px;bottom: 35px;font-size: 12px;color: #fff">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div> 
        </template>
        <template v-else>
            <!-- 女 -->
            <div class="repair4">
                <img src="https://image.giantgocloud.com/www//ImageMapping/image/20230811/D21A594CCD5E46F9A426D2B186ADFA20.png" alt="">
            </div>
            <div style="position: absolute;left: 400px;bottom: 35px;font-size: 12px;color: #1386B0">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div> 
        </template>
       
    </div>
</template>
<script>
import Head from "./component/Head.vue"
import TextListBgc from "./component/TextListBgc.vue"
import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
export default {
    data() {
        return {
            TitleData: {
                icnosW: '132px',
                icnosH: '134px',
                iconUrl: 'https://image.giantgocloud.com/www//ImageMapping/image/20230811/12B7191EB5954125BAF053B76493F6C9.png',
                name: '睡眠',
                textList: [
                    '睡眠是身体恢复和修复的关键时期。在睡眠过程中，身体进行细胞修复、组织再生、免疫功能增强以及代谢调节等重要过程，有助于维持身体各系统的正常功能。 ',
                ]
            },
            textData: [
                {
                    name: '—镁',
                    text: '镁在肌肉运动、神经功能、血压调节、睡眠、免疫和维持健康的血糖水平方面发挥作用。最佳的镁也能改善肌肉力量，增加在短时间、剧烈的运动中出现肌肉疲劳的时间。当你优化了镁元素后，你可能会睡得更好，感觉更快乐。'
                },
                {
                    name: '—红细胞镁',
                    text: '红细胞镁可以测量红细胞中镁的含量。与测量血清中镁的常用方法相比，红细胞镁是体内镁的一种更敏感的测量方法，因为当你血清中的镁水平下降时，你的身体会通过从红细胞中取出镁来弥补损失。因此，你的血清中镁水平会“正常”，即使你的骨骼和组织的镁水平正在下降。这样，红细胞镁就会成为你整体镁状态的一个重要和更动态的指标。'
                },
                {
                    name: '—维生素D',
                    text: '维生素D可以帮助你的身体吸收钙， 以保持强壮和健康的骨骼，对抗感染，并保持健康的体重。你的身体可以从阳光中产生维生素D，你也可以从食物中吸收少量维生素。低维生素D会导致低能量、更多的应力性骨折、炎症增加和肌肉变弱。'
                },
                {
                    name: '—单核细胞',
                    text: '单核细胞在人体健康中扮演着重要的角色，单核细胞是免疫系统中的一类重要细胞，它们参与调节免疫反应和抵抗病原体，它们清除死亡细胞和组织垃圾，促进伤口愈合和组织恢复，分泌抗炎因子，平衡和调节炎症反应，以防止过度炎症反应和组织损伤，对脂肪代谢和能量平衡有一定的调节作用。'
                },
            ],
        }
    },
    components: {
        Head,
        TextListBgc,
        Headers,
        Footer
    },
    props: {
        Sex: String,
        headerData: Object,
    },
    computed: {
        "totalNumber": function () {
            let result = { count: 0, currentPage: 0 }
            result.count = this.headerData.pageCount
            //计算当前页码
            let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "睡眠健康")
            result.currentPage = currentIndex + 1
            return result
        }
    }
}
</script>

<style lang='scss' scoped>
.repair{
    position: relative;
}
.repair4 {
   width: 595px;
   height: 267px;
    img{
        width: 100%;
        height: 100%;
    }
}

.page {
    position: absolute;
    left: 543px;
    bottom: 24px;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background-image: url('https://image.giantgocloud.com/www//ImageMapping/image/20230811/47D3D6C542304B24A4EFC6A4FB3D8064.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
</style>