<template>
    <div class="repair">
        <Headers :headerData="headerData.head" />

        <Head :TitleData="TitleData" />
        <TextList :textData="textData" />
        <div class="repair4"></div>
        <div style="position: absolute;left: 400px;bottom: -70px;font-size: 12px;color: #1386B0">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div>
    </div>
</template>
<script>
import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
import Head from "./component/Head.vue"
import TextList from "./component/TextList.vue"
export default {
    data() {
        return {
            TitleData: {
                icnosW: '101px',
                icnosH: '145px',
                iconUrl: 'https://image.giantgocloud.com/www//ImageMapping/image/20230811/D86A5C90FF1C47D79E682C490E733A81.png',
                name: '新陈代谢',
                textList: ['新陈代谢是人体获得能量的过程，通过代谢食物和营养物质，将其转化为能量以维持生命活动 和日常活动。正常的新陈代谢能够提供足够的能量供应，维持机体的正常功能和活动。']
            },
            textData: [
                {
                    name: '—葡萄糖',
                    text: '葡萄糖来自于消化碳水化合物成一种化学物质，你的身体很容易将其转化为能量。适当调节葡萄糖会给你更多的 能量，改善血压，更好地控制体重。如果你的血糖很高，你就更有可能增加体重，而你患糖尿病、高血压和心脏 健康状况不良的风险也可能会增加'
                },
                {
                    name: '—糖化血红蛋白',
                    text: '糖化血红蛋白代表了过去90120天内你血液中的平均葡萄糖量。如果葡萄糖不立即使用，它可以与红细胞内的血红 蛋白结合。优化的糖化血红蛋白与最佳血糖水平和延长寿命相关'
                },
                {
                    name: '—胰岛素',
                    text: '胰岛素（insulin）是由胰腺的β细胞产生的一种激素。它在人体内起着关键的调节血糖水平和能量代谢的 作用。调节血糖，参与蛋白质和脂肪代谢，胰岛素在肝脏中调节糖原的合成和分解，调节食欲，胰岛素在整 个能量代谢中起着重要作用。'
                },
                {
                    name: '—丙氨酸转氨酶',
                    text: 'ALT是一种主要存在于肝脏中的酶，它有助于化学反应的发生。它在将储存的葡萄糖转化为可用的能量方面发 挥着作用。当出现肝损伤或肌肉损伤时，ALT就会进入血流。血液中通常含有少量的谷丙转氨酶；血液中谷丙 转氨酶含量较高，通常表明肝脏或肌肉受损。'
                },
            ],
            //   coverManImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/98DA1027B2244A42AD2A1821B6E9D48C.png',
            //   coverGirlImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/F47180B6A32243E7A4583CB59C2BF8AB.png',
        }
    },
    components: {
        Head,
        TextList,
        Headers,
        Footer
    },
    props: {
        Sex: String,
        headerData: Object,
    },
    computed: {
        "totalNumber": function () {
            let result = { count: 0, currentPage: 0 }
            result.count = this.headerData.pageCount
            //计算当前页码
            let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "新陈代谢")
            result.currentPage = currentIndex + 1
            return result
        }
    }
}
</script>

<style lang='scss' scoped>
.repair {
    position: relative;
}

.repair1 {
    font-size: 48px;
    color: #1386B0;
    padding-top: 51px;
    padding-left: 45px;
}

.repair2 {
    font-size: 12px;
    color: #4E4E4E;
    padding: 0 45px;
    padding-top: 43px;
}

.repair3 {
    padding-top: 29px;
    padding-left: 45px;

    .title {
        font-size: 14px;
        color: #1386B0;
    }

    .text {
        color: #4E4E4E;
        font-size: 10px;
        padding-top: 4px;
    }
}

.repair4 {
    width: 400px;
    height: 125px;
    margin-top: 30px;
    background-color: #E7F3F7;
}

.page {
    position: absolute;
    left: 543px;
    bottom: -34px;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background-image: url('https://image.giantgocloud.com/www//ImageMapping/image/20230811/47D3D6C542304B24A4EFC6A4FB3D8064.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
</style>