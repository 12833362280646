<template>
    <div class="home1">
        <div style="padding-left: 67px;padding-top: 55px;">
            <h1 style="font-size: 48px;color: #1386B0;">100%个性化 </h1>
        <h1 style="font-size: 48px;color: #1386B0;">生物标志物分析</h1>
        </div>
        <div style="font-size: 14px;color: #4E4E4E;padding-left: 67px;padding-right: 40px;padding-top: 15px;">
            <p>
                您的数据讲述了您的健康故事，识别通用的“临床正常”范围，揭示您身体独特的最佳生物标志物区域。您会发现哪些方面进行了优化，哪些方面还有改进的余地。
            </p>
        </div>
        <div class="home1-img">
           <img src="https://image.giantgocloud.com/www//ImageMapping/image/20230811/F49387F0CDAE4056949CE9161C9A9B8B.png" alt="">
        </div>
        <div style="width: 595px;height: 180px;background-color: #D0E7EF;">

        </div>
    </div>
</template>
<script>
export default {
    props: {
        Sex: String
    }
}
</script>
<style lang='scss' scoped>
    .home1-img{
        position: relative;
        left: 47.5px;
        top: 100px;
        width: 500px;
        height: 300px;
        // margin: 0 auto;
        img{
            width: 100%;
            height: 100%;
        }
    }

</style>