<template>
    <div class="repair">
        <Headers :headerData="headerData.head" />
        <Head :TitleData="TitleData" />
        <TextList :textData="textData" />
        <template v-if="Sex == '男'">
            <div class="repair4">
                <img src="https://image.giantgocloud.com/www//ImageMapping/image/20230810/5CE628273E79483798AB1BEFAA13D7F8.png"
                    alt="">
            </div>
        </template>
        <template v-else>
            <!-- 女 -->
            <div class="repair4">
                <img src="https://image.giantgocloud.com/www//ImageMapping/image/20230811/99AD7263E90A400BA6758C573857AFD6.png"
                    alt="">
            </div>
        </template>
        <div style="position: absolute;left: 400px;bottom: -40px;font-size: 12px;color: #1386B0">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div>
    </div>
</template>
<script>
import Head from "./component/Head.vue"
import TextList from "./component/TextList.vue"
import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
export default {
    data() {
        return {
            TitleData: {
                icnosW: '126px',
                icnosH: '130px',
                iconUrl: 'https://image.giantgocloud.com/www//ImageMapping/image/20230811/7740A9BADE794B5F94B38C2BD2218339.png',
                name: '修复',
                textList: ['修复力是指人体恢复和修复受伤组织的能力。无论是外伤、 手术创伤还是内部炎症，修复力的 强大与否都会直接影响受伤组织的康复速度和质量。']
            },
            textData: [
                {
                    name: '—血红蛋白',
                    text: '皮质醇是一种类固醇激素，身体在压力下会释放出来。你的皮质醇水平在白天波动，早上达到峰值，晚上的水平较低。皮质醇具有重要的功能，如提供快速的能量喷射，维持血糖水平，调节血压，帮助脂肪、蛋白质和碳水化合物的代谢，降低对疼痛的敏感性，以及调节免疫系统。'
                },
                {
                    name: '—睾酮：皮质醇比值',
                    text: '这两种关键激素，总睾酮和皮质醇的比例，表明了你是否在平衡训练和恢复， 以保持你的身体在增加肌肉质 量和力量的最佳状态。'
                }
            ],
            //   coverManImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/98DA1027B2244A42AD2A1821B6E9D48C.png',
            //   coverGirlImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/F47180B6A32243E7A4583CB59C2BF8AB.png',
        }
    },
    components: {
        Head,
        TextList,
        Headers,
        Footer
    },
    props: {
        Sex: String,
        headerData: Object,
    },
    computed: {
        "totalNumber": function () {
            let result = { count: 0, currentPage: 0 }
            result.count = this.headerData.pageCount
            //计算当前页码
            let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "修复2")
            result.currentPage = currentIndex + 1
            return result
        }
    }
}
</script>

<style lang='scss' scoped>
.repair {
    position: relative;
}

.repair1 {
    font-size: 48px;
    color: #1386B0;
    padding-top: 51px;
    padding-left: 45px;
}

.repair2 {
    font-size: 12px;
    color: #4E4E4E;
    padding: 0 45px;
    padding-top: 43px;
}

.repair3 {
    padding-top: 29px;
    padding-left: 45px;

    .title {
        font-size: 14px;
        color: #1386B0;
    }

    .text {
        color: #4E4E4E;
        font-size: 10px;
        padding-top: 4px;
    }
}

.repair4 {
    width: 595px;
    height: 351px;
    margin-top: 59px;

    img {
        width: 100%;
        height: 100%;
    }
}

.page {
    position: absolute;
    left: 543px;
    bottom: -84px;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background-image: url('https://image.giantgocloud.com/www//ImageMapping/image/20230811/47D3D6C542304B24A4EFC6A4FB3D8064.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
</style>
