<template>
    <div class="repair">
        <Headers :headerData="headerData.head" />
        <Head :TitleData="TitleData" />
        <TextListBgc :textData="textData" />
        <div style="position: absolute;left: 400px;bottom: -98px;font-size: 12px;color: #1386B0">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div> 
    </div>
</template>
<script>
import Head from "./component/Head.vue"
import TextListBgc from "./component/TextListBgc.vue"
import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
export default {
    data() {
        return {
            TitleData: {
                icnosW: '154px',
                icnosH: '123px',
                iconUrl: 'https://image.giantgocloud.com/www//ImageMapping/image/20230811/1DA4C1196D584C629A86D37CFEE47F21.png',
                name: '荷尔蒙',
                textList: [
                    '荷尔蒙平衡对女性健康至关重要。它影响月经周期、生殖健康、骨密度、心血管健康、情绪与心理健康以及新陈代谢控制。荷尔蒙失衡可能导致月经不规律、生殖问题、骨质疏松、心血管疾病、情绪波动和体重问题。'
                ]
            },
            textData: [
                {
                    name: '—雌二醇',
                    text: '雌二醇是女性主要的雌激素之一，对生殖系统的正常发育和功能起着关键作用。它促进乳腺发育、子宫内膜的增厚和周期性脱落、卵巢排卵以及性欲的维持， 同时影响着骨健康，心血管健康，情绪和认知功能。'
                },
                {
                    name: '—黄体酮',
                    text: '黄体酮（Progesterone）在女性健康中起着重要的作用，生殖周期和生育，月经周期和调节，黄体酮有助于维持乳腺组织的正常生理状态， 以及情绪和情绪调节。'
                },
                {
                    name: '—硫酸脱氢表雄酮',
                    text: '硫酸脱氢表雄酮（Dehydroepiandrosterone sulfate，DHEAS）是女性体内的一种雄激素前体，在女性体内，一部分硫酸脱氢表雄酮可以转化为雌激素，维持女性性征和正常的生殖功能，骨密度和骨健康，心血管健康，免疫调节，对免疫系统的功能和平衡有一定影响。'
                },
                {
                    name: '—（促甲状腺激素）TSH',
                    text: 'SH由垂体腺体分泌，它刺激甲状腺合成和释放甲状腺激素（T3和T4）。TSH水平的调节对于维持甲状腺功能的正常状态非常重要。如果TSH水平过高，可能表示甲状腺功能低下（甲状腺激素不足）；如果TSH水平过低可能表示 甲状腺功能亢进（甲状腺激素过多）。'
                },
                {
                    name: '—（总三碘甲状腺原氨酸）T3',
                    text: 'T3是甲状腺激素的主要活性形式，对代谢过程至关重要。它促进细胞内能量产生和利用，影响基础代谢率、蛋 白质合成、脂肪分解和碳水化合物代谢，T3水平异常可能导致月经周期紊乱、不孕，增加心血管疾病的风险，同时容易引起焦虑、抑郁、注意力不集中和认知功能下降等。'
                },
                {
                    name: '—总甲状腺激素',
                    text: '总甲状腺激素（T4）对女性的整体健康至关重要。适当的T4水平有助于维持正常的代谢、生殖健康、骨骼健康 心血管健康和心理健康，T4对卵巢功能的调节也对卵巢排卵和黄体形成具有重要影响。'
                },
            ],
        }
    },
    components: {
        Head,
        TextListBgc,
        Headers,
        Footer
    },
    props: {
        Sex: String,
        headerData: Object,
    },

    computed: {
        "totalNumber": function () {
            let result = { count: 0, currentPage: 0 }
            result.count = this.headerData.pageCount
            //计算当前页码
            let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "荷尔蒙分析")
            result.currentPage = currentIndex + 1
            return result
        }
    }
}
</script>

<style lang='scss' scoped>
.repair {
    position: relative;
}

.page {
    position: absolute;
    left: 543px;
    bottom: -44px;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background-image: url('https://image.giantgocloud.com/www//ImageMapping/image/20230811/47D3D6C542304B24A4EFC6A4FB3D8064.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
</style>