<template>
    <div class="repair">
        <Headers :headerData="headerData.head" />
        <div class="repair1">
            <div style="font-size: 38px;color: #1386B0;padding-left: 45px;">荷尔蒙</div>
            <div style="font-size: 12px;color: #4E4E4E;padding: 5px 45px">荷尔蒙平衡对男性健康至关重要。荷尔蒙影响男性性激素调节、生殖健康、骨密度、肌肉质量、
                心血管健康、情绪与心理健康以及代谢水平。适当的荷尔蒙平衡有助于维持性功能、生殖能力、 肌肉质量，降低心血管风险，并维持良好的情绪和能量水平。</div>
        </div>
        <div class="repair2">
            <div class="icons">
                <img :src="TitleData.iconUrl" alt="">
            </div>
        </div>
        <div class="content">
            <div class="repair3" v-for="item in textData">
                <div class="title">{{ item.name }}</div>
                <div class="text">
                    {{ item.text }}
                </div>
            </div>
        </div>
        <div style="position: absolute;left: 400px;bottom: -84px;font-size: 12px;color: #1386B0">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div>
    </div>
</template>
<script>
import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
export default {
    data() {
        return {
            TitleData: {
                icnosW: '171px',
                icnosH: '177px',
                iconUrl: 'https://image.giantgocloud.com/www//ImageMapping/image/20230811/C263B60F82DF482383B6891BD8C29323.png',
                name: '荷尔蒙',
                textList: [
                    '荷尔蒙平衡对男性健康至关重要。荷尔蒙影响男性性激素调节、生殖健康、骨密度、肌肉质量、 心血管健康、情绪与心理健康以及代谢水平。适当的荷尔蒙平衡有助于维持性功能、生殖能力、 肌肉质量，降低心血管风险，并维持良好的情绪和能量水平。'
                ]
            },
            textData: [
                {
                    name: '—睾酮',
                    text: '过量睾酮会降低血液中高密度脂蛋白胆固醇的水平，影响心脏健康，损害性功能和生殖功能。 [1]然而， 睾酮 水平会导致肌肉萎缩、疲劳、抑郁情绪、性欲下降和勃起功能障碍。'
                },
                {
                    name: '—游离睾酮',
                    text: '游离睾酮通常被认为是活跃的生物活性形式，因为它可以更容易地进入细胞并与睾酮受体结合，发挥其 生物学效应。结合睾酮在血液中的存在形式稳定，但它们需要解离才能进入细胞并发挥作用。游离睾酮 在男性健康中扮演着重要的角色，包括性腺功能、性欲和性功能、肌肉质量和力量、骨骼健康以及心理 健康等方面。'
                },
                {
                    name: '—二氢睾酮',
                    text: '这是一种雄激素，是睾酮经过5α-还原酶酶的作用转化而成的代谢产物。DHT在体内具有更高的雄激素活性，与 男性第二性征的发展有关，包括面部和体毛的增长， 以及前列腺的发育。然而，高水平的DHT也与脱发和前列腺 问题有关。'
                },
                {
                    name: '—皮质醇',
                    text: '皮质醇是一种分解代谢激素(促进代谢分解)，是对压力的反应。皮质醇对于提供能量的快速迸发、维持血糖水 平、调节血压、帮助脂肪、蛋白质和碳水化合物的代谢很重要。尽管有这些重要的作用，持续升高的皮质醇水 平会导致体脂增加，损害免疫力，加速衰老过程，并对心理健康产生负面影响。'
                },
                {
                    name: '—促甲状腺激素（TSH）',
                    text: 'SH由垂体腺体分泌，它刺激甲状腺合成和释放甲状腺激素（T3和T4）。TSH水平的调节对于维持甲状腺功能的正常 状态非常重要。如果TSH水平过高，可能表示甲状腺功能低下（甲状腺激素不足）；如果TSH水平过低，可能表示 甲状腺功能亢进（甲状腺激素过多）。'
                },
                {
                    name: '—总三碘甲状腺原氨酸（T3）',
                    text: 'T3是甲状腺激素的主要活性形式，对代谢过程至关重要。它促进细胞内能量产生和利用，影响基础代谢率、蛋 白质合成、脂肪分解和碳水化合物代谢，T3水平异常可能导致月经周期紊乱、不孕，增加心血管疾病的风险，同 时容易引起焦虑、抑郁、注意力不集中和认知功能下降等。'
                },
                {
                    name: '—总甲状腺激素（T4）',
                    text: '总甲状腺激素（T4）对女性的整体健康至关重要。适当的T4水平有助于维持正常的代谢、生殖健康、骨骼健康 心血管健康和心理健康，T4对卵巢功能的调节也对卵巢排卵和黄体形成具有重要影响。'
                },
            ],
        }
    },
    components: {
        Headers,
        Footer
    },
    props: {
        Sex: String,
        headerData: Object,
    },

    computed: {
        "totalNumber": function () {
            let result = { count: 0, currentPage: 0 }
            result.count = this.headerData.pageCount
            //计算当前页码
            let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "荷尔蒙分析")
            result.currentPage = currentIndex + 1
            return result
        }
    }
}
</script>

<style lang='scss' scoped>
.repair {
    position: relative;
}

.repair2 {
    position: absolute;
    left: 410px;
    top: 20px;

    .icons {
        width: 161px;
        height: 167px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.content {
    margin-left: 20px;
    background-color: #E7F3F7;
    padding: 0 20px;
    padding-bottom: 20px;
}

.repair3 {

    padding-top: 10px;

    .title {
        font-size: 12px;
        color: #1386B0;
    }

    .text {
        color: #4E4E4E;
        font-size: 10px;
        padding-top: 4px;
    }
}

.page {
    position: absolute;
    left: 543px;
    bottom: -24px;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background-image: url('https://image.giantgocloud.com/www//ImageMapping/image/20230811/47D3D6C542304B24A4EFC6A4FB3D8064.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
</style>