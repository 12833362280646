<template>
    <div class="repair">
        <Headers :headerData="headerData.head" />
        <Head :TitleData="TitleData" />
        <TextListBgc :textData="textData"/>
        <div style="position: absolute;left: 400px;bottom: -70px;font-size: 12px;color: #1386B0">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div> 
    </div>
</template>
<script>
import Head from "./component/Head.vue"
import TextListBgc from "./component/TextListBgc.vue"
import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
export default {
    data() {
        return {
            TitleData: {
                icnosW: '154px',
                icnosH: '123px',
                iconUrl: 'https://image.giantgocloud.com/www//ImageMapping/image/20230811/D8D95E40936C400FA9FABAE736F15A8B.png',
                name: '耐力',
                textList: [
                    '铁是参与氧气运输的蛋白质的重要组成部分。',
                    ' 在细胞的生长和分化中起着关键作用。内部追踪器测量与铁相关的生物标志物，让你全面了解你体内的铁水平，以及它们是否为性能而优化。'
                ]
            },
            textData: [
                {
                    name: '—血红蛋白',
                    text: '血红蛋白是红细胞中含铁氧转运体。它的主要功能是将氧气从肺部输送到肌肉和大脑。最佳的血红蛋白对峰值能量和耐力至关重要。'
                },
                {
                    name: '—铁蛋白',
                    text: '铁蛋白是一种在体内储存铁的蛋白质。铁对产生血红蛋白至关重要，血红蛋白是红细胞中携带氧气到肌肉和大脑的部分。它在你的神经系统和免疫系统的功能中也起着重要的作用。'
                },
                {
                    name: '—转铁蛋白饱和度',
                    text: 'TS是你的血清铁除以总的铁结合能力，这是你的血液所能携带的最大铁量。转铁蛋白饱和度表明有多少铁实际 上被蛋白质转铁蛋白结合。最佳的转铁蛋白饱和度对于维持你体内的铁平衡很重要。'
                },
                {
                    name: '—总铁结合能力',
                    text: 'TIBC测量你血液所能携带的最大铁含量。拥有最佳的TIBC对于维持你身体的铁平衡很重要。'
                },
                {
                    name: '—红细胞（RBC）计数',
                    text: '你知道红细胞是你血液中最丰富的细胞类型吗？它们在将氧气从肺部输送到全身组织中起着关键作用。一个健康的红细胞计数表明你的身体正在接受它正常工作所需的氧气。'
                },
                {
                    name: '—血清铁',
                    text: '铁是血红蛋白的关键成分，它可以使红细胞在整个体内运输氧气。你的身体从你吃的食物中吸收铁，所以在你的饮食中有良好的铁来源是很重要的，但你摄入的铁只有10%的一部分被吸收。当你的铁水平达到最佳时，你就会有更多的能量，更强壮，想得更好，有一个更有弹性的免疫系统。'
                },
                {
                    name: '—血小板',
                    text: '血小板（platelets），也被称为血小板细胞，是血液中的一种细小的无核细胞片段。它们起着重要的作用，特 别是在止血和血栓形成过程中。'
                },
            ],
        }
    },
    components: {
        Head,
        TextListBgc,
        Headers,
        Footer
    },
    props: {
        Sex: String,
        headerData: Object,
    },
    computed: {
        "totalNumber": function () {
            let result = { count: 0, currentPage: 0 }
            result.count = this.headerData.pageCount
            //计算当前页码
            let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "耐力")
            result.currentPage = currentIndex + 1
            return result
        }
    }
}
</script>

<style lang='scss' scoped>
.repair{
    position: relative;
}
.page {
    position: absolute;
    left: 543px;
    bottom: -24px;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background-image: url('https://image.giantgocloud.com/www//ImageMapping/image/20230811/47D3D6C542304B24A4EFC6A4FB3D8064.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
</style>