<template>
  <div class='recipes'>
    <div class="funMedicine coverTitle" style="margin-top: 0;">
      <div class="coverTitleTop">
        <img src="https://image.giantgocloud.com/www//ImageMapping/image/20231101/0C159F4A4DD44D35A1F581EE94A4B1C9.png"
          alt="">
      </div>
      <div class="coverTitle_title">
        <img src="https://image.giantgocloud.com/www//ImageMapping/image/20231130/2789D6DAE8FF4038BE1F61507A87AC56.png"
          alt="">
      </div>
      <div class="coverTitle_content">
        <img src="https://image.giantgocloud.com/www//ImageMapping/image/20231101/33ACFC1BBF324E9AA89DB82D0FD8EFA1.png"
          alt="">
      </div>
      <div class="messageinfo">
        <div>姓名 / Compellation：{{ listData.reportUserInfo.Name }}</div>
        <div>性别 / Gender：{{ listData.reportUserInfo.Sex }}</div>
        <div>年龄 / Age：{{ listData.reportUserInfo.Age }}</div>
        <div>出生日期 / Date of birth：{{ listData.reportUserInfo.Birth }}</div>
        <div>检测日期 / Test date：{{ listData.reportUserInfo.CheckDate }}</div>
      </div>
      <div class="first_bottom">
        <div class="first_bottom1"><img
            src="https://image.giantgocloud.com/www//ImageMapping/image/20231101/8060313FF8FB416E837B9C1BCA38DC9F.png"
            alt=""></div>
        <div class="first_bottom2">© 2023 SCALLOP CLOUD | ealth Guardian</div>
      </div>
      <!-- <div style="position: absolute;left: 360px;bottom: 10px;;font-size: 12px;color: #1386B0">
            终极健康分析（共{{numPageA}}页）P1
      </div> -->
    </div>
    <div class="funMedicine funMedicinesecod zZjpagesNum">
      <div class="funMedicinesecod_line"></div>
      <div class="funMedicinesecodImg"><img
          src="https://image.giantgocloud.com/www//ImageMapping/image/20231101/5229756316E64CABAF93EC50781B648C.png"
          alt=""></div>
      <div class="funMedicinesecodContend">
        <div class="funMedicinesecodContend1">全面洞察您的生理数据</div>
        <div class="funMedicinesecodContend2">
          终极健康计划测试多达58种血液生物标志物，包括：心脏健康、荷尔蒙健康、新陈代谢、免疫与炎症、睡眠健康、肠道健康、认知力、修复力、耐力，以及营养物质，对您的健康状况进行全面的分析。</div>
        <div class="funMedicinesecodContend3">
          了解您的真实生理年龄是有益健康变化的重要动力和催化剂，由于血液生物标志物受到生活习惯的影响，血液检测有助于确定您需要采取的行动来改善您的健康。即使您现在感觉健康，即使没有身体症状，健康衰老的关键标志也会开始走下坡路。
        </div>
      </div>
      <div style="position: absolute;left: 360px;bottom:30px;font-size: 12px;color: #1386B0">
            终极健康分析（共{{alNum}}页）P1
      </div>
    </div>
    <!-- 目录 -->
    <div class="funMedicine zZjpagesNum">
      <directory :alNum="alNum" :numPageA="numPageA" :Sex="Sex" :directoryList="listData.finalAnalysisSickItem" />
    </div>
    <div class="funMedicine zZjpagesNum">
      <Level :alNum="alNum" :numPageA="numPageA" :Sex="Sex" :LevelDetails="listData.LevelDetails" />
    </div>
    <div v-for="(item,index) in listData.finalAnalysisDataDetailTitleDtos" :key="index">
      <Headers :alNum="alNum" :finalAnalysisDataDetailTitleDtos="item"></Headers>
      <Indicator :alNum="alNum"  :finalAnalysisSickItem="listData.finalAnalysisSickItem[index]" :listData="listData" :typeIndex="index" />
    </div>

    <!-- <template v-if="titleList.includes('新陈代谢')">
      <div class="funMedicine">
        <Metabolism :headerData="headerData" :Sex="Sex" />
      </div>
      <Indicator typeIndex="1" titles="新陈代谢目录" :headerData="headerData" :IndicatorList="IndicatorList[titleList.indexOf('新陈代谢')]" />
    </template>
    <template v-if="titleList.includes('荷尔蒙分析')">
      <template v-if="Sex == '男'">
        <div class="funMedicine">
          <Hormone1 :headerData="headerData" :Sex="Sex" />
        </div>
      </template>
      <template v-else>
        <div class="funMedicine">
          <Hormone2 :headerData="headerData" :Sex="Sex" />
        </div>
      </template>
      <Indicator typeIndex="2" titles="荷尔蒙分析目录" :headerData="headerData" :IndicatorList="IndicatorList[titleList.indexOf('荷尔蒙分析')]" />
    </template>
    <template v-if="titleList.includes('修复')">
      <div class="funMedicine">
        <RepairTwos :headerData="headerData" :Sex="Sex" />
      </div>
      <div class="funMedicine">
        <Repair :headerData="headerData" :Sex="Sex" />
      </div>
      <div class="funMedicine">
        <RepairThree :headerData="headerData" :Sex="Sex" />
      </div>
      <Indicator typeIndex="3" titles="修复目录" :headerData="headerData" :IndicatorList="IndicatorList[titleList.indexOf('修复')]" :newlistData="newlistData"/>
    </template>
    <template v-if="titleList.includes('免疫与炎症')">
      <div class="funMedicine">
        <Inflammation1 :headerData="headerData" :Sex="Sex" />
      </div>
      <div class="funMedicine">
        <Inflammation2 :headerData="headerData" :Sex="Sex" />
      </div>
      <Indicator typeIndex="4" titles="免疫与炎症目录" :headerData="headerData" :IndicatorList="IndicatorList[titleList.indexOf('免疫与炎症')]" :newlistData="newlistData"/>
    </template>
    <template v-if="titleList.includes('耐力')">
      <div class="funMedicine">
        <Endurance :headerData="headerData" :Sex="Sex" />
      </div>
      <Indicator typeIndex="5" titles="耐力目录" :headerData="headerData" :IndicatorList="IndicatorList[titleList.indexOf('耐力')]" :newlistData="newlistData"/>
    </template>
    <template v-if="titleList.includes('睡眠健康')">
      <div class="funMedicine">
        <Sleep :headerData="headerData" :Sex="Sex" />
      </div>
      <Indicator typeIndex="6" titles="睡眠健康目录" :headerData="headerData" :IndicatorList="IndicatorList[titleList.indexOf('睡眠健康')]" :newlistData="newlistData"/>
    </template>
    <template v-if="titleList.includes('认知')">
      <div class="funMedicine">
        <Cognition :headerData="headerData" :Sex="Sex" />
      </div>
      <Indicator typeIndex="7" titles="认知目录" :headerData="headerData" :IndicatorList="IndicatorList[titleList.indexOf('认知')]" :newlistData="IndicatorList"/>
    </template> -->

  </div>
</template>
<script>
import axios from 'axios'
// import { GetFinalAnalysisReport } from '@/api/index'
import Repair from './componetPdf/repair'
import RepairTwos from './componetPdf/repairTwos.vue'
import RepairThree from './componetPdf/repairThree.vue'
import Cardiovascular from './componetPdf/cardiovascular.vue'
import Metabolism from './componetPdf/metabolism.vue'
import Inflammation1 from './componetPdf/inflammation1.vue'
import Inflammation2 from './componetPdf/inflammation2.vue'
import Sleep from './componetPdf/sleep.vue'
import Endurance from './componetPdf/endurance.vue'
import Hormone1 from './componetPdf/hormone1.vue'
import Hormone2 from './componetPdf/hormone2.vue'
import Cognition from './componetPdf/cognition.vue'
import Homepage1 from './componetPdf/homepage1.vue'
import Homepage2 from './componetPdf/homepage2.vue'
import Directory from './componetPdf/directory.vue'
import Indicator from './componetPdf/Indicator.vue'
import Headers from './componetPdf/component/Head.vue'
import Level from './componetPdf/Level.vue'
import H5Report from '@/api/index'
export default {
  data () {
    return {
      alNum: 0,
      listData: [],
      titleList: [],
      titleListIndex: 0,
      reportUserInfo: '',
      finalAnalysisSickItem: [],
      finalAnalysisDataDetailTitleDtos: [],
      newlistData: [],
      Sex: '',
      headerData: {
        pageCount: 0,
        head: '',
        resultArray: []
      },
      // list3: [],
      IndicatorList: [],
      coverManImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/98DA1027B2244A42AD2A1821B6E9D48C.png',
      coverGirlImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/F47180B6A32243E7A4583CB59C2BF8AB.png',
      numPageA: 0
    }
  },
  components: {
    Headers,
    Repair,
    RepairTwos,
    RepairThree,
    Cardiovascular,
    Metabolism,
    Inflammation1,
    Inflammation2,
    Sleep,
    Endurance,
    Hormone1,
    Hormone2,
    Cognition,
    Homepage1,
    Homepage2,
    Directory,
    Indicator,
    Level
  },
  created () {
    const that = this
    const id = this.$query('id')
    that.listData = {}
    if (localStorage.getItem('listDataPdfZ')) {
      that.listData = JSON.parse(localStorage.getItem('listDataPdfZ'))
      console.log('that.listData', that.listData)
      setTimeout(() => {
        localStorage.removeItem('listDataPdfZ')
        localStorage.removeItem('alNum')
      }, 1000)
    } else {
      this.getCustomerFoodWayResult(id)
    }
    console.log('that.listData', that.listData)
  },
  mounted () {
    const that = this
    setTimeout(() => {
      const zZjpagesNum = document.getElementsByClassName('zZjpagesNum')
      localStorage.setItem('alNum', zZjpagesNum.length)
      this.alNum = zZjpagesNum.length
      console.log('zZjpagesNum', zZjpagesNum)
    }, 500)
  },
  methods: {
    numPageAF (e) {
      console.log('接受之', e)
      this.numPageA = e
    },
    // splitArray(array, size) {
    //   let data = [];
    //   for (let i = 0; i < array.length; i += size) {
    //     data.push(array.slice(i, i + size))
    //   }
    //   this.newlistData.push(data)
    //   console.log(data,'data')
    //   console.log(this.newlistData,'newdata')
    // },
    getCustomerFoodWayResult (id) {
      const that = this
      //  https://healthingcoretest.giantgocloud.com/  https://healthingcore365.giantgocloud.com/
      // 'https://healthingcoretest.giantgocloud.com/api/ScenceReport/GetFinalAnalysisReport'
      // 'https://healthingcore365.giantgocloud.com/api/ScenceReport/GetFinalAnalysisReport'
      axios
        .post(
          // 'https://healthingcoretest.giantgocloud.com/api/ScenceReport/GetFinalAnalysisReport'//测试
          // 'https://healthingcore365.giantgocloud.com/api/ScenceReport/GetFinalAnalysisReport'//正式
          `${H5Report}/api/ReportData/GetFinalAnalysisReport`, {
            id
          }
        )
        .then((res) => {
          that.listData = res.data.data
          this.reportUserInfo = that.listData.reportUserInfo
          // 添加目录
          const finalAnalysisSickItem = []
          finalAnalysisSickItem.length = that.listData.finalAnalysisDataDetailTitleDtos.length
          for (let i = 0; i < that.listData.finalAnalysisDataDetailTitleDtos.length; i++) {
            that.listData.finalAnalysisSickItem[i].newFinalAnalysisSickItem = []
            for (let q = 0; q < that.listData.finalAnalysisSickItem.length; q++) {
              if (!that.listData.finalAnalysisSickItem[q].SickTitleDetail.length) {
                const a = {
                  CheckItem: ''
                }
                that.listData.finalAnalysisSickItem[q].SickTitleDetail.push(a)
              }
              if (that.listData.finalAnalysisSickItem[q].SickTitle == that.listData.finalAnalysisDataDetailTitleDtos[i].Theme) {
                finalAnalysisSickItem[i] = that.listData.finalAnalysisSickItem[q]
                console.log('q', q)
                console.log('i', i)
              }
            }

            if (!finalAnalysisSickItem[i]) {
              finalAnalysisSickItem[i] = {}
            }
          }

          that.listData.finalAnalysisSickItem = finalAnalysisSickItem
          console.log('that.listData', that.listData)
        })
    }
  }

}
</script>
<style lang='scss' scoped>
.recipes {
  width: 595px;
  letter-spacing: 2px;

  .funMedicine {
    width: 595px;
    position: relative;
    height: 842px;
    box-sizing: border-box;
    overflow: hidden;

    .page {
      position: absolute;
      width: 40px;
      height: 40px;
      background: #1386b0;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      right: 0px;
      bottom: -10px;
      background: url("../../assets/analysis/be9299d8ce7aecbcc66619a0dd3f403.png") no-repeat;
      background-size: contain;
      text-align: center;
      font-size: 13px;
      padding-left: 2px;
      box-sizing: border-box;
      font-family: DIN-Bold, DIN;
      font-weight: bold;
      color: #ffffff;
      line-height: 33px;
    }
  }

  .coverTitle {
    margin-top: 64px;

    .coverTitleTop {
      width: 76.06px;
      height: 74.22px;
      margin: 62px auto 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .coverTitle_title {
      width: 359px;
      height: 142.35px;
      margin: 58px auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .coverTitle_content {
      width: 498.56px;
      height: 127.56px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .messageinfo {
      width: 498.56px;
      margin: 24px auto 0 auto !important;

      div {
        margin: 7px;
        width: 100%;
        height: 28px;
        background: #EFEFEF;
        opacity: 1;
        line-height: 28px;
        text-align: center;
      }
    }

    .first_bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 90px;

      .first_bottom1 {
        width: 42.98px;
        height: 5.45px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .first_bottom2 {
        font-size: 8px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #9FA0A0;
        line-height: 9px;
      }
    }

    // .coverTitle1 {
    //   display: flex;
    //   justify-content: space-between;

    //   .left {
    //     width: 167px;
    //     height: 109px;

    //     img {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }

    //   .right {
    //     width: 136px;
    //     height: 36px;
    //     margin-right: 34px;
    //     margin-top: 45px;

    //     img {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    // }

    // .coverTitle2 {
    //   width: 492px;
    //   height: 106px;
    //   margin: 0 auto;

    //   .img {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }

    // .coverTitle3 {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   padding-top: 70px;

    //   .left,
    //   .right {
    //     width: 50px;
    //     height: 132px;
    //     background-color: #5AABC8;
    //   }

    //   .center {
    //     width: 495px;
    //     height: 330px;

    //     img {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    // }

    // .coverTitle4,
    // .coverTitle5 {
    //   display: flex;
    //   padding: 0 30px;
    //   padding-top: 43px;
    //   font-size: 20px;
    //   color: #333333;
    //   font-weight: 300;
    // }

    // .coverTitle6 {
    //   display: flex;
    //   padding-top: 56px;
    //   padding-left: 415px;

    //   .left,
    //   .right {
    //     width: 90px;
    //     height: 15px;
    //   }

    //   .left {
    //     background-color: #1286B0;
    //   }

    //   .right {
    //     background-color: #162B87;
    //   }
    // }

  }

  .funMedicinesecod {
    .funMedicinesecod_line {
      width: 500px;
      height: 1px;
      background: #4E4E4E;
      margin: 0 auto;
      margin-top: 61px;
      margin-bottom: 32px;
    }

    .funMedicinesecodImg {
      width: 537px;
      height: 420px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .funMedicinesecodContend {
      width: 500px;
      margin: 0 auto;

      .funMedicinesecodContend1 {
        font-weight: 600;
        color: #000;
        font-size: 12px;
      }

      .funMedicinesecodContend2 {
        color: #4E4E4E;
        font-size: 12px;
        margin: 16px 0;
      }

      .funMedicinesecodContend2 {
        color: #4E4E4E;
        font-size: 12px;
        margin: 16px 0;
      }
    }
  }

}</style>
