<template>
    <div class="repair">
        <Headers :headerData="headerData.head" />
        <Head :TitleData="TitleData" />
        <TextList :textData="textData" />
        <template v-if="Sex == '男'">
            <div class="repair4">
                <img src="https://image.giantgocloud.com/www//ImageMapping/image/20231009/22A709E6E419458AB17626B83BA2D2B2.png"
                    alt="">
            </div>
        </template>
        <template v-else>
            <!-- 女 -->
            <div class="repair4">
                <img src="https://image.giantgocloud.com/www//ImageMapping/image/20231009/22A709E6E419458AB17626B83BA2D2B2.png"
                    alt="">
            </div>
        </template>
        <div style="position: absolute;left: 400px;bottom: 30px;font-size: 12px;color: #FFFFFF">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div> 
    </div>
</template>
<script>
import Head from "./component/Head.vue"
import TextList from "./component/TextList.vue"
import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
export default {
    data() {
        return {
            TitleData: {
                icnosW: '131px',
                icnosH: '158px',
                iconUrl: 'https://image.giantgocloud.com/www//ImageMapping/image/20230811/1A378F483341484399C6C15434F9CA40.png',
                name: '修复',
                textList: ['修复力是指人体恢复和修复受伤组织的能力。无论是外伤、 手术创伤还是内部炎症，修复力的 强大与否都会直接影响受伤组织的康复速度和质量。']
            },
            textData: [
                {
                    name: '—钾',
                    text: '钾在调节血压、心跳、肾功能、钙水平和肌肉细胞的能量使用方面也起着关键作用。当钾处于最佳状态时， 你就会有更好的耐力，更强壮的骨骼，以及更健康的胆固醇和葡萄糖水平。'
                },
                {
                    name: '—钠',
                    text: '钠存在于身体的每个细胞中，特别是在细胞外和细胞之间的液体中。你需要钠来调节细胞内外的水量，以及维持矿物质平衡和血压。如果你参加耐力运动或在锻炼过程中大量出汗，你需要摄入足够的钠来弥补你流汗的量。'
                },
                {
                    name: '—钙',
                    text: '钙对你的骨骼健康和力量至关重要。此外，你还需要这种矿物质来修复肌肉组织，增加肌肉质量，并保持健康 的血压。如果你的钙含量很低，你就会增加患压力性骨折和高血压的风险。'
                }
            ],
            //   coverManImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/98DA1027B2244A42AD2A1821B6E9D48C.png',
            //   coverGirlImg: 'https://image.giantgocloud.com/www//ImageMapping/image/20230810/F47180B6A32243E7A4583CB59C2BF8AB.png',
        }
    },
    components: {
        Head,
        TextList,
        Headers,
   Footer
    },
    props: {
        Sex: String,
        headerData: Object,
    },
    computed: {
        "totalNumber": function () {
            let result = { count: 0, currentPage: 0 }
            result.count = this.headerData.pageCount
            //计算当前页码
            let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "修复3")
            result.currentPage = currentIndex + 1
            return result
        }
    }
}
</script>

<style lang='scss' scoped>
.repair{
    position: relative;
}

.repair4 {
    width: 595px;
    height: 334px;
    margin-top: 59px;

    img {
        width: 100%;
        height: 100%;
    }
}

.page {
    position: absolute;
    left: 543px;
    bottom: 15px;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #1386B0;
    font-size: 20px;
    font-weight: bold;
    background-image: url('https://image.giantgocloud.com/www//ImageMapping/image/20230811/7DBDE02A7F4C4F4EA28FDE9A2A2ED5E3.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
</style>