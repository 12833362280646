<template>
    <div class="repair">
        <Headers :headerData="headerData.head" />
        <Head :TitleData="TitleData" />
        <TextList :textData="textData" />
        <div class="repair4">
            <img src="https://image.giantgocloud.com/www//ImageMapping/image/20230811/F49742BD48254DBCBD3E1CB6ACDAE918.png"
                alt="">
        </div>
        <div style="position: absolute;left: 400px;bottom: -26px;font-size: 12px;color: #1386B0">
            终极健康分析（共{{ totalNumber.count }}页）P{{ totalNumber.currentPage }}
        </div> 
    </div>
</template>
<script>
import Head from "./component/Head.vue"
import TextList from "./component/TextList.vue"
import Headers from "./component/headers.vue"
import Footer from "./component/footer.vue"
export default {
    data() {
        return {
            TitleData: {
                icnosW: '124px',
                icnosH: '140px',
                iconUrl: 'https://image.giantgocloud.com/www//ImageMapping/image/20230811/1F83D90DB6B542D38035984267BE6F6B.png',
                name: '认知',
                textList: ['要想达到你最好的状态，你的思维必须是敏锐的，你的记忆必须是准确的。通过测量叶酸、 镁和维生素B12，打开了一个窗口进入你的大脑功能。']
            },
            textData: [
                {
                    name: '—维生素B12',
                    text: '维生素B12在红细胞的产生以及大脑和神经系统功能中起着重要作用。在B12的最佳水平下，大脑、心脏和身体都会达到最佳状态。如果你的维生素B12含量很低，你可能会出现贫血，导致你感到疲劳和虚弱。B12含量过低也会导致内存问题。随着年龄的增长，你的身体在吸收天然维生素B12方面的效果就会降低。'
                },
                {
                    name: '—叶酸',
                    text: '叶酸，或称叶酸，是一种水溶性维生素，是生产新的红细胞以及制造DNA和RNA所必需的。如果你没有足够的红细胞，你的身体给肌肉提供的氧气就会更少，锻炼后修复肌肉组织的速度也会更慢。'
                }
            ],
        }
    },
    components: {
        Head,
        TextList,
        Headers,
        Footer
    },
    props: {
        Sex: String,
        headerData: Object,
    },
    computed: {
        "totalNumber": function () {
            let result = { count: 0, currentPage: 0 }
            result.count = this.headerData.pageCount
            //计算当前页码
            let currentIndex = this.headerData.resultArray.findIndex(v => v.title == "认知")
            result.currentPage = currentIndex + 1
            return result
        }
    }
}
</script>

<style lang='scss' scoped>
.repair {
    position: relative;
}

.repair1 {
    font-size: 48px;
    color: #1386B0;
    padding-top: 51px;
    padding-left: 45px;
}

.repair2 {
    font-size: 12px;
    color: #4E4E4E;
    padding: 0 45px;
    padding-top: 43px;
}

.repair3 {
    padding-top: 29px;
    padding-left: 45px;

    .title {
        font-size: 14px;
        color: #1386B0;
    }

    .text {
        color: #4E4E4E;
        font-size: 10px;
        padding-top: 4px;
    }
}

.repair4 {
    width: 595px;
    height: 367px;
    margin-top: 39px;

    img {
        width: 100%;
        height: 100%;
    }
}

.page {
    position: absolute;
    left: 543px;
    bottom: -44px;
    width: 53px;
    height: 40px;
    text-align: center;
    line-height: 53px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background-image: url('https://image.giantgocloud.com/www//ImageMapping/image/20230811/47D3D6C542304B24A4EFC6A4FB3D8064.png');
    background-repeat: no-repeat;
    background-size: 100%;
}
</style>